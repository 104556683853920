<template>
    <v-row class="row--sm">
        <template v-for="item in items">
            <v-col cols="12" :key="item">
                <v-checkbox hide-details="auto" :label="item.name" />
            </v-col>
        </template>
    </v-row>
</template>

<script>
export default {
    data: () => ({
        items: [
            {
                name: "크리스탈 유리",
            },
            {
                name: "토기",
            },
            {
                name: "도자기",
            },
            {
                name: "프리미엄 본 도자기",
            },
            {
                name: "프리미엄 도자기",
            },
            {
                name: "목재",
            },
        ],
    }),
};
</script>