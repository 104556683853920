<template>
    <div>
        <div class="txt txt--sm txt--dark pb-12px pb-md-16px">Filter:</div>

        <v-row class="row--sm">
            <template v-for="(filtering, index) in filterings">
                <v-col :key="index" cols="auto">
                    <filter-menu v-bind="{ filtering }"/>
                </v-col>
            </template>
        </v-row>

        <v-sheet color="grey lighten-5" class="filter-sheet mt-18px mt-24px mb-30px mb-md-56px pt-12px pb-24px">
            <div class="txt txt--sm mb-8px">Show results for:</div>
            <v-row class="row--xs">
                <v-col v-for="(filter, index) in filters" :key="index" cols="auto">
                    <v-chip outlined color="grey darken-3" class="rounded-xs">
                        <span v-if="['diameter', 'length', 'width', 'height'].includes(filter.key)">{{ `${filter.key} ${filter.value}mm` }}</span>
                        <v-icon class="ml-8px">mdi-close</v-icon>
                    </v-chip>
                </v-col>
            </v-row>
        </v-sheet>
    </div>
</template>

<script>
import FilterMenu from "@/components/client/shop/products/filter/product-list-filter/filter-menu.vue";

export default {
    components: {
        FilterMenu,
    },
    props: {
        filterings: { type: Array, default: () => [] },
    },
    data: () => ({
        filters: [],
    }),
    computed: {
    },
    methods: {
        sync() {
            let { ...query } = this.$route.query;
            this.filters = Object.entries(query).map(([key, value]) => ({ key, value }));
        }
    },
    watch: {
        "$route.query"() {
            this.sync();
        }
    }
};
</script>

<style lang="scss" scoped>
.filter-sheet {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        background-color: inherit;
        height: 100%;
        width: 100vw;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        z-index: -1;
    }
}
</style>