<template>
    <v-row class="row--sm">
        <v-col cols="12">
            <v-text-field hide-details="auto" dense outlined prepend-inner-icon="mdi-magnify" placeholder="이름으로 검색하세요" class="rounded-0"></v-text-field>
        </v-col>
        <v-col cols="12">
            <v-row class="row--sm">
                <template v-for="item in items">
                    <v-col cols="12" :key="item">
                        <v-checkbox hide-details="auto" :label="item.name" />
                    </v-col>
                </template>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props:{ 
        filtering: { type: Object, default: () => ({}) },
    },
    data: () => ({
        items: [
            {
                name: "컬렉션 이름1",
            },
            {
                name: "컬렉션 이름2",
            },
            {
                name: "컬렉션 이름3",
            },
        ],
    }),
};
</script>