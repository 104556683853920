<template>
    <v-btn-toggle multiple>
        <template v-for="item in items">
            <v-btn width="44" class="pa-0" :key="item">
                <div class="d-flex flex-column">
                    <v-avatar size="30" class="mx-auto" :style="`background: ${item.color};`" :class="item.name === '하얀색' ? 'border' : ''">
                        <v-icon class="font-size-20" :color="item.light ? 'black' : 'white'">mdi-check</v-icon>
                    </v-avatar>
                    <div class="mt-8px font-size-12">
                        <div class="text-truncate">
                            {{ item.name }}
                        </div>
                        <div class="grey--text text--lighten-2">(1)</div>
                    </div>
                </div>
            </v-btn>
        </template>
    </v-btn-toggle>
</template>

<script>
export default {
    props:{ 
        filtering: { type: Object, default: () => ({}) },
    },
    data: () => ({
        items: [
            {
                name: "녹색",
                color: "rgb(112, 180, 33)",
            },
            {
                name: "파란색",
                color: "rgb(1, 58, 223)",
            },
            {
                name: "빨간색",
                color: "rgb(208, 2, 27)",
            },
            {
                name: "하얀색",
                color: "#fff",
                light: true,
            },
            {
                name: "클리어",
                color: "linear-gradient(326deg, rgba(255, 255, 255, 0.48), rgba(205, 205, 205, 0.42) 43%, rgba(255, 255, 255, 0))",
                light: true,
            },
            {
                name: "분홍색",
                color: "rgb(247, 134, 186)",
            },
            {
                name: "베이지",
                color: "rgb(240, 226, 178)",
                light: true,
            },
            {
                name: "갈색",
                color: "rgb(103, 61, 31)",
            },
            {
                name: "회색",
                color: "rgb(134, 147, 163)",
            },
            {
                name: "검은색",
                color: "#000",
            },
            {
                name: "여러색",
                color: "linear-gradient(to right, rgb(208, 104, 2), rgb(248, 231, 28) 36%, rgb(114, 202, 13) 63%, rgb(0, 198, 255))",
                light: true,
            },
            {
                name: "짙은 회색",
                color: "rgb(181, 172, 157)",
            },
            {
                name: "구리색",
                color: "rgb(177, 61, 31)",
            },
            {
                name: "노란색",
                color: "rgb(255, 255, 102)",
                light: true,
            },
            {
                name: "터키 옥색",
                color: "rgb(64, 156, 156)",
            },
        ],
    }),
    methods: {
        isWhite() {
            return;
        },
    },
};
</script>

<style lang="scss" scoped>
.v-btn-toggle {
    margin: -6px;
    flex-wrap: wrap;
    justify-content: center;
    .v-btn {
        height: auto !important;
        border: none !important;
        background-color: transparent !important;
        min-width: 0 !important;
        opacity: 1;
        margin: 6px;
        .v-avatar {
            position: relative;
            overflow: visible;
            .v-icon {
                opacity: 0;
            }
        }
        &::before {
            display: none;
        }

        &--active {
            .v-avatar {
                .v-icon {
                    opacity: 1;
                }
            }
        }
    }
}
</style>