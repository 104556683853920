var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "txt txt--sm txt--dark pb-12px pb-md-16px"
  }, [_vm._v("Filter:")]), _c('v-row', {
    staticClass: "row--sm"
  }, [_vm._l(_vm.filterings, function (filtering, index) {
    return [_c('v-col', {
      key: index,
      attrs: {
        "cols": "auto"
      }
    }, [_c('filter-menu', _vm._b({}, 'filter-menu', {
      filtering
    }, false))], 1)];
  })], 2), _c('v-sheet', {
    staticClass: "filter-sheet mt-18px mt-24px mb-30px mb-md-56px pt-12px pb-24px",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "txt txt--sm mb-8px"
  }, [_vm._v("Show results for:")]), _c('v-row', {
    staticClass: "row--xs"
  }, _vm._l(_vm.filters, function (filter, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-chip', {
      staticClass: "rounded-xs",
      attrs: {
        "outlined": "",
        "color": "grey darken-3"
      }
    }, [['diameter', 'length', 'width', 'height'].includes(filter.key) ? _c('span', [_vm._v(_vm._s(`${filter.key} ${filter.value}mm`))]) : _vm._e(), _c('v-icon', {
      staticClass: "ml-8px"
    }, [_vm._v("mdi-close")])], 1)], 1);
  }), 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }