var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn-toggle', {
    attrs: {
      "multiple": ""
    }
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-btn', {
      key: item,
      staticClass: "pa-0",
      attrs: {
        "width": "44"
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('v-avatar', {
      staticClass: "mx-auto",
      class: item.name === '하얀색' ? 'border' : '',
      style: `background: ${item.color};`,
      attrs: {
        "size": "30"
      }
    }, [_c('v-icon', {
      staticClass: "font-size-20",
      attrs: {
        "color": item.light ? 'black' : 'white'
      }
    }, [_vm._v("mdi-check")])], 1), _c('div', {
      staticClass: "mt-8px font-size-12"
    }, [_c('div', {
      staticClass: "text-truncate"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]), _c('div', {
      staticClass: "grey--text text--lighten-2"
    }, [_vm._v("(1)")])])], 1)])];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }