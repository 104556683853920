<template>
    <v-menu rounded="0" :close-on-content-click="false" offset-y v-model="menu">
        <template #activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" x-small class="filter-button" :color="menu ? 'primary darken-3' : 'grey lighten-5'">
                <div class="d-flex align-center" :class="menu ? 'white--text' : 'grey--text text--darken-3'">
                    <span class="font-size-16">
                        {{ filtering?.key?.name }}
                    </span>
                    <v-avatar size="16" :color="menu ? 'white' : 'black'" class="ml-8px">
                        <span class="font-size-12" :class="menu ? 'black--text' : 'white--text'">1</span>
                    </v-avatar>

                    <v-icon>
                        <template v-if="menu">mdi-chevron-up</template>
                        <template v-else>mdi-chevron-down</template>
                    </v-icon>
                </div>
            </v-btn>
        </template>

        <v-card tile min-width="300" max-width="320" class="filter-menu">
            <v-app-bar flat color="primary darken-3">
                <span class="white--text">{{ count }}개 선택됨</span>
                <v-spacer />
                <v-btn x-small text color="white" class="pa-0" @click="remove">
                    <div class="d-flex align-center">
                        <v-icon class="font-size-16 mr-1">mdi-close</v-icon>
                        선택 취소
                    </div>
                </v-btn>
            </v-app-bar>
            <v-card-text>
                <template v-if="filtering?.key?.code === 'Dimensions'">
                    <filter-demensions v-bind="{ filtering, value: filters }" />
                </template>

                <template v-if="filtering?.key?.code === 'Colour'">
                    <filter-color v-bind="{ filtering }" />
                </template>

                <template v-if="filtering?.key?.code === 'Price'">
                    <filter-price v-bind="{ filtering }" />
                </template>

                <template v-if="filtering?.key?.code === 'Categories'">
                    <filter-category v-bind="{ filtering }" />
                </template>

                <template v-if="filtering?.key?.code === 'Collections'">
                    <filter-collections v-bind="{ filtering }" />
                </template>

                <template v-if="filtering?.key?.code === 'Material'">
                    <filter-material v-bind="{ filtering }" />
                </template>

                <template v-if="filtering?.key?.code === 'Dishwasher safe'">
                    <filter-dishwasher v-bind="{ filtering }" />
                </template>

                <template v-if="filtering?.key?.code === 'Microwave safe'">
                    <filter-microwave v-bind="{ filtering }" />
                </template>

                <template v-if="filtering?.key?.code === 'Style'">
                    <filter-style v-bind="{ filtering }" />
                </template>

                <template v-if="filtering?.key?.code === 'Volume'">
                    <filter-volume v-bind="{ filtering }" />
                </template>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
import FilterDemensions from "./filter-dimensions.vue";
import FilterColor from "./filter-color.vue";
import FilterPrice from "./filter-price.vue";
import FilterCategory from "./filter-category.vue";
import FilterCollections from "./filter-collections.vue";
import FilterMaterial from "./filter-material.vue";
import FilterDishwasher from "./filter-dishwasher.vue";
import FilterMicrowave from "./filter-microwave.vue";
import FilterStyle from "./filter-style.vue";
import FilterVolume from "./filter-volume.vue";

export default {
    components: {
        FilterDemensions,
        FilterColor,
        FilterPrice,
        FilterCategory,
        FilterCollections,
        FilterMaterial,
        FilterDishwasher,
        FilterMicrowave,
        FilterStyle,
        FilterVolume,
    },
    props: {
        filtering: { type: Object, default: () => ({}) },
    },
    data: () => ({
        menu: false,
        filters: [],
    }),
    computed: {
        count() {
            let { ...query } = this.$route.query;
            switch (this.filtering.key.code) {
                case "Dimensions": {
                    return ["diameter", "length", "width", "height"].filter((v) => Object.keys(query).includes(v)).length;
                }
            }
        },
    },
    methods: {
        remove() {
            let { ...query } = this.$route.query;
            delete query.diameter;
            delete query.length;
            delete query.width;
            delete query.height;

            this.$router.push(query);
        },
    },
};
</script>

<style lang="scss" scoped>
.v-btn.filter-button {
    border-radius: 999px !important;
    padding: 0 18px !important;
    border: 1px solid;
    border-color: var(--v-grey-lighten3) !important;
    .v-icon {
        font-size: 24px;
    }
    &::before {
        display: none;
    }
}
.filter-menu {
    ::v-deep {
        .v-toolbar {
            &,
            &__content {
                height: auto !important;
            }
            &__content {
                padding: 4px 12px;
            }
        }
    }
    .v-card__text {
        padding: 24px 18px 18px;
    }
}
@media (min-width: 768px) {
    .filter-button {
        padding: 0 24px !important;
    }
}
</style>