var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "rounded-0",
    attrs: {
      "hide-details": "auto",
      "dense": "",
      "outlined": "",
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": "이름으로 검색하세요"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_vm._l(_vm.items, function (item) {
    return [_c('v-col', {
      key: item,
      attrs: {
        "cols": "12"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "hide-details": "auto",
        "label": item.name
      }
    })], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }